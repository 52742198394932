import React from 'react';
import './App.css';
import Card from './components/Card';
import projectsData from './projectsData';

function App() {
  return (
    <div className="app-container">
      <h1 className="title">Sei Spooktacular</h1>
      <p className="subtitle">
        A spooky collaboration to celebrate Halloween 2024. Featuring art from Blemished Ducks, Cool Cows, Crafty Canines, KorpDogs, and TOSHIS.
      </p>
      <div className="cards-container">
        {projectsData.map((project) => (
          <Card
            key={project.id}
            title={project.title}
            description={project.description}
            twitter={project.twitter}
            discord={project.discord}
            mintPage={project.mintPage}
            image={project.image}
          />
        ))}
      </div>
    </div>
  );
}

export default App;
