import React from 'react';
import './Card.css';

function Card({ title, description, twitter, discord, mintPage, image }) {
  return (
    <div className="card">
      <img src={image} alt={title} className="card-image" />
      <div className="card-content">
        <h2 className="card-title">{title}</h2>
        <p className="card-description">{description}</p>
        <div className="card-links">
          <p><a href={mintPage} target="_blank" rel="noopener noreferrer">Mint</a></p>
          <p><a href={twitter} target="_blank" rel="noopener noreferrer">Twitter</a></p>
          <p><a href={discord} target="_blank" rel="noopener noreferrer">Discord</a></p>
        </div>
      </div>
    </div>
  );
}

export default Card;
