const projectsData = [
  {
    id: 1,
    title: "Blemished Ducks",
    description: "Waddle into a quacky Halloween!",
    twitter: "https://x.com/BlemishedDucks",
    discord: "https://discord.com/invite/et6KDzaAeP",
    mintPage: "https://www.frensei.io/launchpad/Sei%20Spooktacular%20-%20Blemished%20Ducks",
    image: "/images/card1.png"
  },
  {
    id: 2,
    title: "Cool Cows",
    description: "Join the Cool Cows on an eerie adventure this Halloween!",
    twitter: "https://x.com/coolcow_sei",
    discord: "https://discord.com/invite/coolcows",
    mintPage: "https://www.frensei.io/launchpad/SEI%20Spooktacular/",
    image: "/images/card2.png"
  },
  {
    id: 3,
    title: "Crafty Canines",
    description: "Get crafty with the canines this Halloween season.",
    twitter: "https://x.com/crafty_canines",
    discord: "https://discord.com/invite/NRmRcmJV6w",
    mintPage: "https://www.frensei.io/launchpad/Sei%20Spooktacular%20-%20Crafty%20Canines",
    image: "/images/card3.png"
  },
  {
    id: 4,
    title: "KorpDogs",
    description: "Experience a spooky night with KorpDogs.",
    twitter: "https://twitter.com/korpdogs",
    discord: "https://discord.com/korpdogs",
    mintPage: "https://www.frensei.io/launchpad/Sei%20Spooktacular%20-%20KorpDogs",
    image: "/images/card4.png"
  },
   {
    id: 5,
    title: "TOSHI",
    description: ".... .- .--. .--. -.--  .... .- .-.. .-.. --- .-- . . -.",
    twitter: "https://x.com/TOSHIS__",
    discord: "https://discord.gg/QUUbF4c5",
    mintPage: "https://www.frensei.io/launchpad/Sei%20Spooktacular%20-%20TOSHI",
    image: "/images/card5.png"
  }
];

export default projectsData;
